/*
 * Custom
 */
$( document ).ready(function() {

    // Knowledgebase active category

    $(function() {

        var url = window.location.href;
        $('.knowledgebase-category-categories .wrapper .item').each(function() {
            if ($(this).attr('href') == url) {
                $(this).addClass('active');
            }
        });
    });

    $(function() {

        var url = window.location.href;
        $('.sidebar ul li a').each(function() {
            if ($(this).attr('href') == url) {
                $(this).parent().addClass('active');
            }
        });
    });

    // Scroll to anchor

    $(".archive .knowledgebase-content .sidebar ul li ul li a").on('click', function(e) {
        e.preventDefault();
        var target = $(this).attr('href');
        $('html, body').animate({
            scrollTop: ($(target).offset().top - 155)
        }, 800);
    });


    jQuery ( document ).ready ( function($) {
        var hash= window.location.hash
        if ( hash == '' || hash == '#' || hash == undefined ) return false;
        var target = $(hash);
        headerHeight = 120;
        target = target.length ? target : $('[name=' + hash.slice(1) +']');
        if (target.length) {
            $('html,body').stop().animate({
                scrollTop: target.offset().top - 155 //offsets for fixed header
            }, 800);
        }
    } );

    // Sub menu

    $('.open-sub-first').on('mouseenter', function() {
        $(this).addClass('active');
        $('.open-sub-second').removeClass('active');
    });

    $('.open-sub-first').on('mouseleave', function() {
        $(this).removeClass('active');
    });

    $('.open-sub-second').on('mouseenter', function() {
        $(this).addClass('active');
        $('.open-sub-first').removeClass('active');
    });

    $('.open-sub-second').on('mouseleave', function() {
        $(this).removeClass('active');
    });

    // Add close

    $('.add-close').on('click', function () {
       $('.add').slideUp();
       $('.home-hero').addClass('add-closed');
       $('.payslip-hero').addClass('add-closed');
       $('.invoicing-hero').addClass('add-closed');
       $('.download-hero').addClass('add-closed');
       $('.blog-hero').addClass('add-closed');
    });

    // Sticky bar

    $(window).scroll(function() {
        var scroll = $(window).scrollTop();
        if (scroll >= 144) {
            $('.header').removeClass('nav-up').addClass('nav-down');

        } else {
            $('.header').addClass('nav-up').removeClass('nav-down');

        }
    });

    // Highlight sidebar

    $(window).on('scroll', function() {
        $('.subcat-name').each(function() {
            if($(window).scrollTop() >= $(this).offset().top - 165) {
                var id = $(this).attr('id');
                $('.sidebar a').removeClass('active');
                $('.sidebar a[href="#'+ id +'"]').addClass('active');
            }
        });
    });


    // var position = $(window).scrollTop();
    //
    // $(window).scroll(function() {
    //     var scroll = $(window).scrollTop();
    //
    //     if (scroll < 1) {
    //         $('.header').removeClass('nav-up').removeClass('nav-down');
    //     }
    //     else if (scroll > position) {
    //         $('.header').removeClass('nav-up').addClass('nav-down');
    //         $('.home-hero').removeClass('nav-up').addClass('nav-down');
    //     } else {
    //         $('.header').removeClass('nav-down').addClass('nav-up');
    //         $('.home-hero').removeClass('nav-down').addClass('nav-up');
    //     }
    //     position = scroll;
    // });

    // Hamburger

    $('.hamburger a').on('click', function () {
       $(this).toggleClass('active');
       $('.nav-mobile').toggleClass('active');
    });

    // Slider testimonials

    var swiper = new Swiper(".slider-testimonials", {
        slidesPerView: 'auto',
        initialSlide: 2,
        spaceBetween: 40,
        loop: true,
        centeredSlides: true,
        roundLengths: true,
        grabCursor: true,
        speed: 1000,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    // Slider related

    var swiper = new Swiper(".slider-related", {
        slidesPerView: 3,
        spaceBetween: 30,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
        breakpoints: {
            768: {
                slidesPerView: 1,
                spaceBetween: 30
            }
        }
    });

    // Slider knowledgebase guides

    var swiper = new Swiper(".slider-guides", {
        slidesPerView: 1,
        spaceBetween: 30,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    // Slider knowledgebase categories

    var swiper = new Swiper(".slider-categories", {
        slidesPerView: 'auto',
        spaceBetween: 10,
        centeredSlides: true,
        mousewheelControl: false,
        loop: true,
        navigation: {
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
        },
    });

    // Counter

    (function ($) {
        var controller = new ScrollMagic.Controller();
        function animateNumber() {
            $(".js-count").each(function () {
                var $this = $(this),
                    countTo = $this.attr("data-count");
                $({ countNum: $this.text() }).animate(
                    {
                        countNum: countTo
                    },
                    {
                        duration: 3000,
                        easing: "linear",
                        step: function () {
                            // Count up with commas
                            $this.text(Math.floor(this.countNum).toLocaleString("en"));
                        },
                        complete: function () {
                            // Add comma after done counting
                            $this.text(this.countNum.toLocaleString("en"));
                        }
                    }
                );
            });
        }

        new ScrollMagic.Scene({
            triggerElement: ".home-numbers"
        })
            .on("enter", function () {
                $(".home-numbers").addClass("pod--animated");
                animateNumber();
            })
            .on("leave", function () {
                $(".home-numbers").removeClass("pod--animated");
            })
            .addTo(controller);
    })(jQuery);

    // Parallax elements

    var scene = document.getElementById('scene1');
    var parallaxInstance = new Parallax(scene, {
        hoverOnly: true
    });
    parallaxInstance.friction(0.2, 0.2);

    var scene = document.getElementById('scene11');
    var parallaxInstance = new Parallax(scene, {
        hoverOnly: true
    });
    parallaxInstance.friction(0.2, 0.2);

    var scene = document.getElementById('scene2');
    var parallaxInstance = new Parallax(scene, {
        hoverOnly: true
    });
    parallaxInstance.friction(0.2, 0.2);

    var scene = document.getElementById('scene22');
    var parallaxInstance = new Parallax(scene, {
        hoverOnly: true
    });
    parallaxInstance.friction(0.2, 0.2);

    var scene = document.getElementById('scene3');
    var parallaxInstance = new Parallax(scene, {
        hoverOnly: true
    });
    parallaxInstance.friction(0.2, 0.2);

    var scene = document.getElementById('scene4');
    var parallaxInstance = new Parallax(scene, {
        hoverOnly: true
    });
    parallaxInstance.friction(0.2, 0.2);

    // AOS

    AOS.init();

    // Success form

    var wpcf7Elm = document.querySelector( '.wpcf7' );

    wpcf7Elm.addEventListener( 'wpcf7mailsent', function( event ) {
        $('.show-after').show();
        $('.show-before').hide();
    }, false );
});
$( document ).ready(function() {

    // Parallax elements Home page

    var controller = new ScrollMagic.Controller();

    // Parallax element 1

    var tween = new TimelineMax()
        .add([
            TweenMax.fromTo("#parallax-1", 2, {scale: 1, bottom: -88}, {bottom: 100, ease: Linear.easeNone}),
        ]);

    var parallaxScene1 = new ScrollMagic.Scene({
        triggerElement: ".home-hero",
        offset: -350,
        duration: 2500
    })
        .setTween(tween)
        .addTo(controller);

    // Parallax element 2

    var tween = new TimelineMax()
        .add([
            TweenMax.fromTo("#parallax-2", 2, {scale: 1, bottom: 0}, {bottom: 100, ease: Linear.easeNone}),
        ]);

    var parallaxScene2 = new ScrollMagic.Scene({
        triggerElement: ".home-numbers",
        offset: -350,
        duration: 2500
    })
        .setTween(tween)
        .addTo(controller);

    // Parallax element 3

    var tween = new TimelineMax()
        .add([
            TweenMax.fromTo("#parallax-3", 2, {scale: 1, top: 240}, {top: 0, ease: Linear.easeNone}),
        ]);

    var parallaxScene3 = new ScrollMagic.Scene({
        triggerElement: ".home-why",
        offset: -350,
        duration: 2500
    })
        .setTween(tween)
        .addTo(controller);

    // Parallax elements Payslip page

    // Parallax element 1

    var tween = new TimelineMax()
        .add([
            TweenMax.fromTo("#parallax-1-1", 2, {scale: 1, top: 90}, {top: -90, ease: Linear.easeNone}),
        ]);

    var parallaxScene1 = new ScrollMagic.Scene({
        triggerElement: ".payslip-testimonial",
        offset: -350,
        duration: 2500
    })
        .setTween(tween)
        .addTo(controller);

    // Parallax element 2

    var tween = new TimelineMax()
        .add([
            TweenMax.fromTo("#parallax-2-1", 2, {scale: 1, bottom: -144}, {bottom: 144, ease: Linear.easeNone}),
        ]);

    var parallaxScene2 = new ScrollMagic.Scene({
        triggerElement: ".payslip-testimonial",
        offset: -350,
        duration: 2500
    })
        .setTween(tween)
        .addTo(controller);

    // Parallax element 3

    var tween = new TimelineMax()
        .add([
            TweenMax.fromTo("#parallax-3-1", 2, {scale: 1, bottom: 30}, {bottom: 190, ease: Linear.easeNone}),
        ]);

    var parallaxScene3 = new ScrollMagic.Scene({
        triggerElement: ".payslip-included",
        offset: -350,
        duration: 2500
    })
        .setTween(tween)
        .addTo(controller);

    // Parallax element 4

    var tween = new TimelineMax()
        .add([
            TweenMax.fromTo("#parallax-4", 2, {scale: 1, bottom: 50}, {bottom: 300, ease: Linear.easeNone}),
        ]);

    var parallaxScene3 = new ScrollMagic.Scene({
        triggerElement: ".contacts-form-full",
        offset: -350,
        duration: 2500
    })
        .setTween(tween)
        .addTo(controller);

    // Parallax element 5

    var tween = new TimelineMax()
        .add([
            TweenMax.fromTo("#parallax-5", 2, {scale: 1, top: -100}, {top: -300, ease: Linear.easeNone}),
        ]);

    var parallaxScene3 = new ScrollMagic.Scene({
        triggerElement: ".contacts-info",
        offset: -350,
        duration: 2500
    })
        .setTween(tween)
        .addTo(controller);

    // Parallax element 6

    var tween = new TimelineMax()
        .add([
            TweenMax.fromTo("#parallax-6", 2, {scale: 1, top: -30}, {top: -160, ease: Linear.easeNone}),
        ]);

    var parallaxScene3 = new ScrollMagic.Scene({
        triggerElement: ".report-video",
        offset: -350,
        duration: 2500
    })
        .setTween(tween)
        .addTo(controller);

    // Parallax element 7

    var tween = new TimelineMax()
        .add([
            TweenMax.fromTo("#parallax-7", 2, {scale: 1, bottom: -90}, {bottom: 90, ease: Linear.easeNone}),
        ]);

    var parallaxScene3 = new ScrollMagic.Scene({
        triggerElement: ".report-video",
        offset: -350,
        duration: 2500
    })
        .setTween(tween)
        .addTo(controller);

    // Parallax element 8

    var tween = new TimelineMax()
        .add([
            TweenMax.fromTo("#parallax-8", 2, {scale: 1, top: 0}, {top: -145, ease: Linear.easeNone}),
        ]);

    var parallaxScene3 = new ScrollMagic.Scene({
        triggerElement: ".report-text.first",
        offset: -350,
        duration: 2500
    })
        .setTween(tween)
        .addTo(controller);

    // Parallax element 9

    var tween = new TimelineMax()
        .add([
            TweenMax.fromTo("#parallax-9", 2, {scale: 1, top: 50}, {top: -100, ease: Linear.easeNone}),
        ]);

    var parallaxScene3 = new ScrollMagic.Scene({
        triggerElement: ".report-text.second",
        offset: -350,
        duration: 2500
    })
        .setTween(tween)
        .addTo(controller);

    // Parallax element 10

    var tween = new TimelineMax()
        .add([
            TweenMax.fromTo("#parallax-10", 2, {scale: 1, top: 100}, {top: -150, ease: Linear.easeNone}),
        ]);

    var parallaxScene3 = new ScrollMagic.Scene({
        triggerElement: ".report-text.third",
        offset: -350,
        duration: 2500
    })
        .setTween(tween)
        .addTo(controller);
});
$( document ).ready(function() {

});